import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import React from 'react'
import { useSelector } from 'react-redux'
import DataRow from './DataRow'
const useStyles = makeStyles({
    table: {
        minWidth: 650
    }
})

export default function Recent() {
    const classes = useStyles()
    const dashboard = useSelector(state => state.dashboard)
    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="Recent table">
                <TableHead>
                    <TableRow>
                        <TableCell>Student</TableCell>
                        <TableCell>Assignment</TableCell>
                        <TableCell>File</TableCell>
                        <TableCell>Update</TableCell>
                        <TableCell>Status</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {dashboard.recentData.map(item => (
                        <DataRow item={item} key={`${item.id}`}/>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}
