import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import HomeIcon from '@material-ui/icons/HomeRounded';
import LogoutIcon from '@material-ui/icons/ExitToAppRounded'
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Dashboard from './Dashboard';
import { Route, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from './Redux/authSlice';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import amber from '@material-ui/core/colors/amber';
import { IMAGE_URL } from '../utils/constants';
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        backgroundColor:"#ffffff",
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,

    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        backgroundColor:"#ffffff"
    },
    bottomPush: {
        position: "fixed",
        bottom: 0,
        textAlign: "center",
        paddingBottom: 10,
        width: drawerWidth
    },
    avatarText: {
        color: theme.palette.getContrastText(amber[500]),
        backgroundColor: amber[500],
    },
    title: {
        fontFamily: "Lora, serif",
        textTransform: "uppercase",
        [theme.breakpoints.up('sm')]: {
            flexGrow: 1,
        },
    }
}));

function Main(props) {
    const { window } = props;
    const auth = useSelector(state => state.auth)
    const dispatch = useDispatch()
    const classes = useStyles();
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const location = useLocation()
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    const history = useHistory()
    const changeRoute = (route) => {
        history.push(route)
    }
    useEffect(() => {
        if (auth.token === null) {
            history.replace('/')
        }
        // eslint-disable-next-line
    }, [auth.token])
    const drawer = (
        <div>
            <div className={classes.toolbar} />
                <ListItem button key={"dashboard"} onClick={() => changeRoute('/dashboard')} selected={location.pathname === "/dashboard" ? true : false}>
                    <ListItemIcon><HomeIcon color={location.pathname === "/dashboard" ?'secondary':'inherit'}/></ListItemIcon>
                    <ListItemText primary="Dashboard" primaryTypographyProps={{ variant: "body2" }} />
                </ListItem>
            <div className={classes.bottomPush}>
                <ListItem button key={"profile"} onClick={() => changeRoute('/settings')} selected={location.pathname === "/settings" ? true : false}>
                    <ListItemAvatar>
                        {auth.user.image ?
                            <Avatar alt={auth.user.name} src={IMAGE_URL+auth.user.image} />
                            :
                            <Avatar className={classes.avatarText}>{auth.user.name.substring(0, 1)}</Avatar>
                        }
                    </ListItemAvatar>
                    <ListItemText primary={auth.user.name} />
                </ListItem>
                <ListItem button key={"logout"} onClick={() => dispatch(logout())}>
                    <ListItemIcon><LogoutIcon /></ListItemIcon>
                    <ListItemText primary="Logout" />
                </ListItem>
            </div>

        </div>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        className={classes.menuButton}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h4" noWrap className={classes.title} align="center">
                        Select School Melbourne
          </Typography>
                </Toolbar>
            </AppBar>
            <nav className={classes.drawer} aria-label="mailbox folders">
                <Hidden smUp implementation="js">
                    <Drawer
                        container={container}
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
                <Hidden xsDown implementation="js">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
            </nav>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <Route path={`${props.match.path}dashboard`} exact={true} component={Dashboard} />
            </main>
        </div>
    );
}

Main.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

export default Main;
