import Tabs from '@material-ui/core/Tabs';
import AppBar from '@material-ui/core/AppBar';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getDashboardData } from './Redux/dashboardSlice'
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Recent from './Components/Recent';
import Students from './Components/Students';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
    },
  }));
function Dashboard() {
    const classes = useStyles();
    const [value, setValue] = useState(0)
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    useEffect(() => {
        dispatch(getDashboardData({
            token: auth.token
        }))
        // eslint-disable-next-line
    }, [])
    function handleChange(event,newValue){
        setValue(newValue);
    };
    return (
        <div className={classes.root}>
            <AppBar position="static" color="default">
                <Tabs value={value} onChange={handleChange} aria-label="Dashboard tabs">
                    <Tab label="Recent" {...a11yProps(0)}/>
                    <Tab label="Students" {...a11yProps(1)}/>
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <Recent/>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Students/>
            </TabPanel>
        </div>
    )
}

export default Dashboard
