import makeRequest from "../../utils/makeRequest";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

export const getDashboardData = createAsyncThunk(
    'dashboard/getData',
    async (data, thunkAPI) => {
        const response = await makeRequest(data.token, null, thunkAPI).get('/')
        return response.data
    }
)
export const updateSubmission = createAsyncThunk(
    'dashboard/updateSubmission',
    async (data, thunkAPI) => {
        const response = await makeRequest(data.token, 'multipart/form-data', thunkAPI).post('/updateSubmission', data.body)
        return response.data
    }
)
export const getSuggestion = createAsyncThunk(
    'dashoard/getSuggestions',
    async (data, thunkAPI) => {
        const response = await makeRequest(data.token, null, thunkAPI).post('/getSuggestions', data.body)
        return response.data
    }
)
export const getStudentData = createAsyncThunk(
    'dashboard/getStudentData',
    async(data,thunkAPI)=>{
        const response = await makeRequest(data.token,null,thunkAPI).post('/getStudentWritingData',data.body)
        return response.data
    }
)
const dashboardSlice = createSlice({
    name: "dashboard",
    initialState: {
        recentData: [],
        studentData: [],
        loading: false,
        loadingItem: "",
        suggestions: [],

    },
    reducers: {
        setLoadingItem(state, action) {
            state.loadingItem = action.payload
        }
    },
    extraReducers: {
        [getDashboardData.fulfilled]: (state, action) => {
            state.loading = false
            state.recentData = action.payload.data
        },
        [getDashboardData.pending]: (state, action) => {
            state.loading = true
        },
        [getDashboardData.rejected]: (state, action) => {
            state.loading = false
        },
        [updateSubmission.fulfilled]: (state, action) => {
            state.loading = false
            let item = state.recentData.find(x => x.id === action.payload.data.id)
            item.test_data = action.payload.data.test_data
        },
        [updateSubmission.pending]: (state, action) => {
            state.loading = true
        },
        [updateSubmission.rejected]: (state, action) => {
            state.loading = false
        },
        [getSuggestion.fulfilled]: (state, action) => {
            state.loading = false
            state.suggestions = action.payload.data
        },
        [getSuggestion.pending]: (state, action) => {
            state.loading = true
        },
        [getSuggestion.rejected]: (state, action) => {
            state.loading = false
        },
        [getStudentData.fulfilled]: (state, action) => {
            state.loading = false
            state.studentData = action.payload.data
        },
        [getStudentData.pending]: (state, action) => {
            state.loading = true
        },
        [getStudentData.rejected]: (state, action) => {
            state.loading = false
        },
    }
})
export const { setLoadingItem } = dashboardSlice.actions
export default dashboardSlice.reducer