import { CircularProgress, Icon, TableCell, TableRow } from '@material-ui/core';
import React from 'react'
import DoneAll from '@material-ui/icons/DoneAllRounded'
import Remove from '@material-ui/icons/RemoveRounded'
import { IMAGE_URL } from '../../utils/constants'
import { useDispatch, useSelector } from 'react-redux';
import { setLoadingItem, updateSubmission } from '../Redux/dashboardSlice';

function DataRow({ item }) {
    let data = JSON.parse(item.test_data);
    const dashboard = useSelector(state => state.dashboard)
    const auth = useSelector(state => state.auth)
    const dispatch = useDispatch()
    function update(event) {
        let formData = new FormData()
        formData.append("id", item.id)
        formData.append("data",item.test_data)
        formData.append("upload", event.target.files[0])
        formData.append("filename",`${item.user_id}-${item.test_id}-${Object.keys(data)[0]}`)
        dispatch(setLoadingItem(item.id))
        dispatch(updateSubmission({
            token:auth.token,
            body:formData
        }))
        event.target.value = ""
    }
    return (
        <TableRow>
            <TableCell>{item.user_name}</TableCell>
            <TableCell>{item.name}</TableCell>
            <TableCell><a href={`${IMAGE_URL}${data[Object.keys(data)[0]]}`}>Download</a></TableCell>
            <TableCell>
                <input
                    type="file"
                    disabled={(dashboard.loading && dashboard.loadingItem === item.id)? true : false}
                    onChange={update}
                    accept="application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" />
            </TableCell>
            {(dashboard.loading && dashboard.loadingItem === item.id)?
                <TableCell>
                    <CircularProgress size={28} />
                </TableCell>
                :
                <TableCell>
                    {data.checked ?
                        <Icon>
                            <DoneAll />
                        </Icon>
                        :
                        <Icon>
                            <Remove />
                        </Icon>
                    }
                </TableCell>}
        </TableRow>
    )
}

export default DataRow
