import { createSlice } from "@reduxjs/toolkit";

export const notifySlice = createSlice({
    name: "notify",
    initialState: {
        notifications: []
    },
    reducers: {
        enqueue: {
            reducer: (state, action) => {
                state.notifications.push({
                    key: action.payload.notification.key,
                    ...action.payload.notification
                })
            },
            prepare: notification => {
                const key = notification.options && notification.options.key;
                return {
                    payload: {
                        notification: {
                            ...notification,
                            key: key || new Date().getTime() + Math.random(),
                        },
                    }
                }
            }
        },
        remove: (state, action) => {
            state.notifications = state.notifications.filter(notification => notification.key !== action.payload)
        }
    }
})
export const { enqueue, remove } = notifySlice.actions
export default notifySlice.reducer