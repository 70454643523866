import { CircularProgress, ClickAwayListener, Grow, makeStyles, MenuItem, MenuList, Paper, Popper, TextField } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles({
    field: {
        width: `300px`
    },
    pop: {
        width: `300px`,
        overflow:"auto",
        maxHeight:`300px`
    },
    wrapper:{
        padding:`8px`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    }
})
export default function SearchBox({ label, value, onChange, onSelected, data, loading }) {
    const classes = useStyles()
    const [open, setOpen] = React.useState(false)
    function handleChange(event) {
        if (event.target.value.trim() !== "") {
            setOpen(true)
        } else {
            setOpen(false)
        }
        onChange(event)
    }
    function select(id) {
        onSelected(id)
        setOpen(false)
    }
    const anchorRef = React.useRef(null);
    return (
        <ClickAwayListener onClickAway={() => {
            setOpen(false)
        }}>
            <div className={classes.field}>
                <TextField
                    label={label}
                    variant="outlined"
                    size="small"
                    value={value}
                    onChange={handleChange}
                    ref={anchorRef}
                    onFocus={(e) => {
                        if (e.target.value.trim() !== "") {
                            setOpen(true)
                        }
                    }}
                    className={classes.field}
                />
                <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'bottom' }}
                        >
                            <Paper className={classes.pop}>

                                {loading ?
                                    <div className={classes.wrapper}>
                                        <CircularProgress size={24} />
                                    </div>
                                    :
                                    <MenuList id="menu-list-grow">
                                        {data.map(item => (
                                            <MenuItem key={`${item.id}`} onClick={() => { select(item.id) }}>{item.name}</MenuItem>
                                        ))}
                                    </MenuList>
                                }
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </div>
        </ClickAwayListener>
    )
}
