import { CircularProgress, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getStudentData, getSuggestion } from '../Redux/dashboardSlice'
import DataRow from './DataRow'
import SearchBox from './SearchBox'

const useStyles = makeStyles({
    table: {
        minWidth: 650
    },
})
export default function Students() {
    const classes = useStyles()
    const dispatch = useDispatch()
    const dashboard = useSelector(state => state.dashboard)
    const auth = useSelector(state => state.auth)
    function fetchSuggestions(e) {
        if (e.target.value.trim() === "") {
            return
        }
        dispatch(getSuggestion({
            token: auth.token,
            body: {
                search: e.target.value
            }
        }))
    }
    function onSelect(id) {
        dispatch(getStudentData({
            token: auth.token,
            body: {
                id: id
            }
        }))
    }
    return (
        <div>
            <SearchBox label="Search" data={dashboard.suggestions} loading={dashboard.loading} onChange={fetchSuggestions} onSelected={onSelect} />
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="Recent table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Student</TableCell>
                            <TableCell>Assignment</TableCell>
                            <TableCell>File</TableCell>
                            <TableCell>Update</TableCell>
                            <TableCell>Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dashboard.studentData.length > 0 ?
                            dashboard.studentData.map(item => (
                                <DataRow item={item} key={`${item.id}`} />
                            ))
                            :
                            <TableRow>
                                <TableCell colSpan={5} align="center">{dashboard.loading ? <CircularProgress />:"No data available"}</TableCell>
                            </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}
